import Link from "next/link";
import React from "react";
import Image from "next/legacy/image";

const ProjectCard = (props) => {
  const { project, id, className } = props;

  return (
    <div
      className={`bg-center bg-no-repeat bg-cover rounded-[20px] shadow-inset_color h-full ${className}`}
      style={{ backgroundImage: `url(${project?.thumb})` }}
    >
      <Link href={`/project-details/${id}`} className="h-full">
        <CardText project={project} />
      </Link>
    </div>
  );
};

const CardText = ({ project }) => {
  return (
    <div className="h-full rounded-[20px] bg-gradient-to-tr from-primary to-secondary backdrop-blur-sm py-5 px-4 transition-all opacity-0 hover:opacity-100">
      <div className="text-white  flex flex-col  gap-10 px-4 py-6 items-start">
        <div className="bg-white h-full px-3 py-2 text-sm text-black rounded-full">
          {project?.tags[0]}
        </div>

        <h1 className="text-xl font-black font-header text-text">
          {project?.title}
        </h1>

        <p className="text-sm line-clamp-5">{project?.overview}</p>

        <div className="bg-white rounded-xl p-4 flex gap-4 justify-between mt-auto w-full">
          {project?.technologies.slice(0, 5).map((logo, index) => (
            <div key={index} className="relative h-10 w-10">
              <Image
                src={`/assets/images/projects/technologies/${logo}`}
                layout="fill"
                objectFit="contain"
                className="absolute inset-0"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
